import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { AboutUs } from '@common/pages'

const query = graphql`
  query AboutUs {
    strapiAboutUsCanada {
      sections: Section {
        title
        description
        image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl: url
      }
    }
  }
`

const AboutUsPage = () => {
  const {
    strapiAboutUsCanada: { sections },
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(query)

  return <AboutUs sections={sections} siteUrl={siteUrl} />
}

export default AboutUsPage
